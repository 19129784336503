@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@import "~video-react/dist/video-react.css";

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

ul.nav {
  width: 60%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #007373;
  border-radius: 6px;
  padding-left: 0px;
  font-weight: 600;
  letter-spacing: 1.5px;
  color: #007373;

  @media (max-width: 768px) {
    width: 90%;
  }
}

ul.nav li {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.7s;
}

ul.nav li:hover {
  background-color: #fff;
}

ul.nav li.active {
  background: linear-gradient(
    50deg,
    rgba(0, 141, 141, 0.9) 12%,
    rgba(0, 179, 179, 0.7) 77%
  );
  color: white;
}

.rd3t-label {
  translate: -80px 33px !important;
  font-size: 18px;
  background-color: red;
}

.rd3t-leaf-node {
  font-weight: 100;
  font-variant-position: sub;
}

.node__root > circle {
  fill: rgb(255, 255, 255);
  stroke: #e21717;
}

.node__branch > circle {
  fill: rgb(255, 255, 255);
  stroke: #f8e327f3;
}

.node__leaf > circle {
  fill: rgb(255, 255, 255);
  stroke: #10e256;
}

.ant-collapse-header {
  width: 80vw;
}

.nav-active {
  background: linear-gradient(
    50deg,
    rgba(0, 141, 141, 0.3) 12%,
    rgba(0, 179, 179, 0.7) 77%
  );
  box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.28);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.wrapper.four {
  font-size: 3rem;
  font-family: "Play", sans-serif;
}

.four .type {
  width: auto;
  margin: auto;
}

.four h3 {
  width: 12ch;
  border-right: 4px solid #00b3b3;
  margin: 2rem auto;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 2s steps(13, end),
    blink-caret 0.5s step-end infinite alternate;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink-caret {
  50% {
    border-color: transparent;
  }
}

.ant-select-selector {
  background: #fff !important;
  height: 2rem !important;
  border-radius: 0px !important;
  border-color: #007373;
}

.ant-select-selection-item {
  font-size: 1rem !important;
  padding-top: 0.2rem !important;
  color: rgb(68, 68, 68);
}

.ant-picker .ant-picker-input > input[disabled] {
  color: black;
}

.rbc-time-view .rbc-label {
  display: none;
}
.rbc-time-view .rbc-allday-cell {
  min-height: calc(100vh - 70px);
  max-height: unset;
}
.rbc-time-view .rbc-time-content {
  display: none;
}

.project-body {
  width: calc(100% - 25rem);
}

.search-user > .ant-select-selector {
  border: 1px solid rgb(217, 217, 217) !important;
  border-radius: 4px !important;
}

.address-field > .ant-input {
  background-color: #fff !important;
  color: #282c34;
  font-size: 1rem !important;
  border: none !important;
}

.address-field > .ant-input:disabled {
  background-color: #fbfafe !important;
}

.address-field {
  border: none !important;
}
