.ant-input {
  background: #e8f0f2;
}

.ant-picker-calender {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  padding: 0.7rem;
  flex: 3;
  margin-right: 1rem;
}

.ant-picker-panel {
  background: rgba(255, 255, 255, 0.7);
}

.ant-input-affix-wrapper {
  background: #e8f0f2;
}

.video-react-control {
  display: none;
}
.video-react-control-text {
  display: none;
}

.ant-collapse-header {
  width: unset;
}
